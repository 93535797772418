.aboutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 60px;
}

.aboutTitle{
    font-size: 30px;
    color: white;
}

.aboutText{
    width: 50%;
    color: white;
    text-align: center;
}

.aboutText a{
    color: white;
    text-decoration: underline;
    transition: ease-out 0.2s;
}

.aboutText a:hover{
    color: black;
    transition: ease-in 0.2s;
}