.nav{
    background: var(--accent-one);
    width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: last baseline;
}

.navHeader{
    margin: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1;
    width: fit-content;
}

.navTitle{
    margin: 0px 0px 5px 0px;
    font-weight: 400;
}

.navTitle a{
    text-decoration: none;
    color: black;
    transition: ease-out 0.2s;
}

.navTitle a:hover{
    color: white;
    transition: ease-in 0.2s;
}

.navTagline{
    margin: 0px;
    font-weight: 400;
    font-size: 18px;
}

#taglineWord{
    margin: 0px;
    display: inline;
    color: white;
}

.navList{
    margin: 2px 10px 10px 0px;
    width: 50%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navItem{
    font-size: 18px;
    text-decoration: none;
    color: black;
    transition: ease-out 0.2s;
}

.navItem:hover{
    color: white;
    transition: ease-in 0.2s;
}

.menuButton{
    margin: 0px 0px 10px 0px;
    padding: 0px;
	border-color: transparent;
	background-color: transparent;
	display: none;
    width: fit-content;
    height: fit-content;
}

.menuIcon{
    margin: 0px;
    padding: 0px;
    transform: scale(2.5);
}


@media only screen and (max-width: 1000px){
    .navHeader{
        margin: 5px;
    }

    .navTagline{
        font-size: 16px;
    }

    .navList{
        margin: 2px 5px 5px 0px; 
    }

    .navItem{
        font-size: 16px;
    }
}

@media only screen and (max-width: 800px) {
    .nav{
        justify-content: space-around;
    }

    .navList{
        display: none;
    }

    .navList.expanded{
        position: absolute;
        display: block;
        flex-direction: column;
        width: fit-content;

        top: 60px;
        right: 0px;
        padding: 0px;
        margin-right: 0px;
        background-color: var(--accent-one);
    }

    .navList.expanded li{
        margin: 5px;
    }

    .menuButton{
        display: inline;
    }
}