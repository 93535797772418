:root{
	--background-color: #D55C89;
	--accent-one: #9DAB44;
	--accent-two: #DF6934;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: var(--background-color);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858E4A;
}