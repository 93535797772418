/** ALL POSTS GRID PAGE **/
.allPosts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 60px;
}

.postsTitle{
    font-size: 30px;
    color: white;
}

.postPreviewContainer{
    color: white;
    width: 70%;
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-bottom: 50px;
}

.postPreview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    margin: 10px 0px 10px 0px;
    padding-left: 10px;
    border-radius: 10px;
    text-decoration: none;
    color: var(--accent-two);
    background-color: white;

    width: 80%;
    height: fit-content;

    transform: scale(1.0);
    transition: ease-out 0.2s;
}

.postPreview:hover{
    transform: scale(1.01);
    transition: ease-in 0.2s;
}

.postPreviewImage{
    width: 100px;
}

.postPreviewInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
}

.postPreviewCategory{
    width: fit-content;
    padding: 3px;
    margin: 10px 0px 10px 0px;
    color: white;
    background-color: var(--accent-one);
}

.postPreviewInfo h4 {
    margin: 2px 2px 7px 2px;
    font-size: 18px;
}

.postPreviewDate{
    margin: 0px 0px 5px 0px;
}

.postPreviewAuthor{
    margin: 0px 0px 20px 0px;
}

.postPreviewText{
    margin-top: 0px;
}

/** INDIVIDUAL POST PAGE **/
.postContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 60px;
}

.post{
    display: flex;
    flex-direction: column;
    
    width: 60%;
    height: fit-content;
    background-color: white;
    padding: 10px;
}

.postCategory{
    width: fit-content;
    padding: 4px;
    margin: 0px;
    color: white;
    background-color: var(--accent-one);
    transform: scale(1.0);
    transition: ease-out 0.1s;
}

.postCategory:hover{
    transform: scale(1.01);
    transition: ease-in 0.1s;
}

.postCategory h5{
    margin: 0px;
}

.postLocation{
    margin: 0px;
}

.postByline{
    margin: 0px 0px 20px 0px;
}

.postAuthorLink{
    color: var(--accent-two);
}

.postAuthorLink:hover{
    color: var(--accent-one);
}

.post img{
    width: 20%;
    margin: 10px;
}

@media only screen and (max-width: 1000px){
    .postPreviewContainer{
        width: 80%;
    }

    .postPreview{
        width: 90%;
    }

    .post{
        width: 70%;
    }
}

@media only screen and (max-width: 480px) {
    .postPreviewContainer{
        width: 100%;
    }

    .postPreview{
        width: 90%;
    }

    .post{
        width: 80%;
    }
}