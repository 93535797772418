.contributorsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 60px;
}

.contributorsTitle{
    font-size: 30px;
    color: white;
}

.contributorTiles{
    width: 100%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contributorTile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 45%;
    background-color: white;
    border-radius: 25px;
    margin: 20px;
}

.contributorName{
    font-size: 20px;
    color: white;
    padding: 5px;
    margin: 15px;
    background-color: var(--accent-one);
    width: fit-content;
}

.contributorBio{
    padding: 3px;
    margin: 15px;
}

.contributorTile a{
    padding: 5px;
    margin: 15px;
    width: fit-content;
    color: white;
    background-color: var(--accent-two);
    text-decoration: none;

    transform: scale(1.0);
    transition: ease-out 0.2s;
}

.contributorTile a:hover{
    transform: scale(1.05);
    transition: ease-in 0.2s;
}

@media only screen and (max-width: 1000px){
    .contributorTile{
        width: 60%;
    }
}

@media only screen and (max-width: 480px) {
    .contributorTile{
        width: 90%;
    }
}